<template>
	
	<div class="cont" id="p17">
            <div class="page-title">{{ program.name }}</div>
			
            <div class="c-list">
                <div class="c01">
                    <div class="s01">
                        <div class="s-item">
                            <div class="s-header">
                                <div class="s-title">가맹점명</div>
                            </div>
                            <div class="s-body">
                                <div class="s-content w100">
									<div class="si-title">가맹점명</div>
									<div class="si-desc setting">
									
										<input type="text" placeholder="가맹점명을 선택하세요"
											v-model="item.businessName"
											readonly
											@click="setOverlay(true)"
											style="background: #eee;"
										>
										<a @click="setOverlay(true)">
											<div class="btn-type01"
											>검색</div></a>
									</div>
                                </div>
							</div>
                        </div>
                        <div class="s-item corporate_business active"> <!-- 법인/개인 사업자 class "active" -->
                            <div class="s-header">
                                <div class="s-title">단말기 정보</div>
                            </div>
                            <div class="s-body">
                                <div class="s-content">
                                    <div class="si-title">단말기 종류<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <select>
											<option 
												v-for="(item, index) in codes.posType"
												:key="index"
												:value="item.code"
											>{{ item.codeName }}</option>
										</select>
                                    </div>
								</div>
                                <div class="s-content">
								</div>
                                <div class="s-content w100">
                                    <div class="si-title">모델명<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="모델명을 입력하세요"
											v-model="item.terminalName"
											:rules="[rules.max(item, 'terminalName', 50)]"
										>
                                    </div>
								</div>
                                <div class="s-content w100">
                                    <div class="si-title">단말기 번호(TID)<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="단말기번호를 입력하세요"
											v-model="item.terminalCode"
											:rules="[rules.max(item, 'terminalCode', 50)]"
										>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="s04">
						<router-link :to="{ name: 'MerchantsTerminalList' }"><div class="btn-cancel mr-10">목록</div></router-link>
                        <a @click="save"><div class="btn-submit">등록</div></a>
                    </div>
                </div>
            </div>
			
			<MerchantsTerminalPopupList
				v-if="show.merchants"
				
				@setOverlay="setOverlay"
				@setMerchant="setMerchant"
			/>
        </div>

</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { date } from '@/resources/date/date'
	import { rules } from '@/resources/rules/rules'
	
	import MerchantsTerminalPopupList from '@/view/Merchants/Terminal/MerchantsTerminalPopupList'
	
	export default{
		name: "MerchantsTerminalItem"
		,props: ['user', 'codes']
		,components: { MerchantsTerminalPopupList }
		,data: function(){
			return {
				program: {
					name: '단말기 등록'
				}
				,item:{
				}
				,search: {
				}
				,search_default: {
					inquiryStartDate: date.getToday()
					,inquiryEndDate: ''
					,keyword: ''
					,posType: ''
					,status: ''
				}
				,show: {
					merchants: false
				}
				,rules: rules
			}
		}
		,computed: {
		}
		,methods: {
			save: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/merchants/terminal'
						,data: this.item
						,authorize: true
					})
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '단말기 신청이 등록되었습니다.' })
						this.$router.push({ name: 'MerchantsTerminalList'})
			
						await this.setOverlay(false)
					}else{
						this.$emit('setNotify', { tyoe: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { tyoe: 'error', message: E})
				}
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
				this.show.merchants = type
			}
			,setMerchant: async function({ pgMerchNo, businessName }){
				this.$set(this.item, 'pgMerchantNo', pgMerchNo)
				this.$set(this.item, 'businessName', businessName)
				await this.close()
			}
			,close: function(){
				this.$emit('setOverlay', false)
				this.show.merchants = false
			}
		}
		,created: function(){
		}
		,watch: {
		}
	}
</script>








