<template>
	<div style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%;  ">
			<div class="popup type03" style="width: 730px; margin: 100px auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div>
				<div class="p-header">
						<div class="title">{{ program.name }}</div>
						<div class="btn-close_popup" @click="close">닫기</div>
					</div>
					<div class="p-body" style="">
						<div class="c01" style="padding: 0; border: none;">
							<input type="text" 
								style="border: 1px solid #ddd; padding: 10px; width: 88%; margin-right: 10px; color: black;"
								placeholder="가맹점명을 입력하세요"
								v-model="search.keyword"
							>
							<v-btn class="success" @click="getData">검색</v-btn>
						</div>
						<div class="c02" style="padding: 0; margin: 10px 0 0; ">
							<div class="table type05" > 
								<div class="item-header" style="height: 58px; overflow-y: scroll;">
									<div class="item">
										<div class="td" style="width: 90%;">가맹점</div>
										<div class="td">선택</div>
									</div>
								</div>
								<div 
									v-if="items.length"
									class="item-list" style="height: 450px; overflow-y: scroll;">
									<div class="item"
										v-for="(item, index) in items"
										:key="index"
									>
										<div class="td" style="width: 90%; text-align: left;">{{ item.businessName }}</div>
										<div class="td"><v-btn color="success" @click="setMerchant({ pgMerchNo: item.pgMerchNo, businessName: item.businessName})" style="background-color:#00bfa5 ;" small>선택</v-btn></div>
									</div>
								</div>
								<div
									v-if="!items.length"
									class="no-data"
								>
									No Data
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	
	export default{
		name: 'AgentPopupItem'
		,props: ['reason']
		,data: function(){
			return {
				program: {
					name: '가맹점 조회'
				}
				,items: [
				]
				,search: {
					inquiryEndDate: ''
					,inquiryStartDate: '20200101'
					,keyword: ''
					,status: ''
					,limitApplyStatus: ''
					,page: 1
					,list_cnt: 10
					,page_cnt: 10
					,total_count: 1
					,link:'/Franchisee/'
				}
			}
		}
		,methods: {
			getData: async function(){
			
				try{
					let result = await Axios({
						method: 'get'
						,url: '/merchants'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						this.$set(this.search, 'total_page', result.data.content.totalPages)
						this.$set(this.search, 'total_count', result.data.content.totalElements)
						this.$set(this.search, 'list_cnt', result.data.content.size)
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,close: function(){
				this.$emit('setOverlay', false)
			}
			,setMerchant: function({ pgMerchNo, businessName }){
				this.$emit('setMerchant', { pgMerchNo: pgMerchNo, businessName: businessName })
			}
		}
		,watch: {
			
		}
	}
</script>




